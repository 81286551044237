import { useMemo } from 'react';

import { CSVValueTransform } from 'src/components/generate-report/generate-report';
import { slashParticipantField } from 'src/components/rankings-run/rankings-run.utils';
import { ListTypeEnum } from 'src/graphql-types/globalRankingTypes';

const useCsvTransforms = (listType, t) => {
  return useMemo(() => {
    const commonTransforms = [
      { key: 'id', label: t('id') },
      { label: t('usta id'), ...slashParticipantField('itemId') },
      { label: t('name'), ...slashParticipantField('name') },
      { label: t('city'), ...slashParticipantField('city') },
      { label: t('state'), ...slashParticipantField('state') },
      { label: t('section'), ...slashParticipantField('section') },
      { label: t('district'), ...slashParticipantField('district') },
      {
        label: t('birth date'),
        key: 'participants',
        transforms: [
          {
            operation: CSVValueTransform.ARRAY_FIELD_SELECT,
            parameters: [{ key: 'fieldPath', value: 'birthDate' }],
          },
          {
            operation: CSVValueTransform.FORMAT_UTC_DATE,
            parameters: [{ key: 'dateFormat', value: 'MM-DD-YYYY' }],
          },
          {
            operation: CSVValueTransform.ARRAY_JOIN,
            parameters: [{ key: 'delimiter', value: ' / ' }],
          },
        ],
      },
    ];

    if (listType === ListTypeEnum.ELIGIBILITY) {
      return commonTransforms; // No rank or points for ELIGIBILITY
    }

    return [
      ...commonTransforms,
      { key: 'sectionRank', label: t('section position') },
      { key: 'districtRank', label: t('district position') },
      { key: 'rank', label: t('national rank') },
      { key: 'points.singles', label: t('singles points') },
      { key: 'points.doubles', label: t('doubles points') },
      { key: 'points.bonus', label: t('bonus points') },
      { key: 'points.total', label: t('total points') },
    ];
  }, [listType, t]);
};

export default useCsvTransforms;
