import { navigate } from 'gatsby';
import { TFunction } from 'i18next';
import { CSVValueTransform } from 'src/components/generate-report/generate-report';
import {
  DistrictEnum,
  DivisionTypeEnum,
  MatchFormatEnum,
  MatchFormatTypeEnum,
  PlayerLevelEnum,
  PlayerTypeEnum,
  SectionEnum,
} from 'src/graphql-types/globalRankingTypes';
import {
  Ranklist_ranklist_rankingItemsPaginated_items as Item,
  Ranklist_ranklist as Ranklist,
} from 'src/graphql-types/Ranklist';

export const handleFullNamesAndSort = (participants: Item['participants']): any => {
  const sortedParticipants = sortParticipants(participants);

  return [...sortedParticipants?.map((p) => p?.name?.split(' ')), ...sortedParticipants?.map((p) => p.itemId)];
};

export const sortParticipants = (participants: Item['participants']) => {
  if (!participants) return [];
  return [...participants].sort((a, z) => {
    const [, aLastName] = a?.name ? a.name.toLowerCase().split(' ') : [];
    const [, zLastName] = z?.name ? z.name.toLowerCase().split(' ') : [];
    return aLastName > zLastName ? 1 : -1;
  });
};

export const isTeamDoubles = (
  matchFormat: Ranklist['matchFormat'] | undefined,
  matchFormatType: Ranklist['matchFormatType'] | undefined,
): boolean => {
  return matchFormat === MatchFormatEnum.DOUBLES && matchFormatType === MatchFormatTypeEnum.TEAM;
};

export const navigateToPlayerProfile = (playerId: string, ranklistId?: string) => {
  navigate(`/players/${playerId}?tab=rankings&ranklistId=${ranklistId}`);
};

export const handleRowClick = (row: Item, ranklist?: Ranklist) => {
  const [participant] = row?.participants ?? [];
  const playerId = participant?.itemId;
  if (playerId && ranklist) navigateToPlayerProfile(playerId, ranklist?.id);
};

export const handleWordSplit = (first?: string | null, second?: string | null) => {
  return !second ? first : `${first} / ${second}`;
};

export const isDateRangeSameYear = (startDate: Date, endDate: Date): boolean => {
  return startDate?.getFullYear() === endDate?.getFullYear();
};

export const getSectionEnumOptions = (t: TFunction): { value: SectionEnum | ''; label: string }[] => {
  return [
    { value: '', label: t('any section') },
    { value: SectionEnum.CARIBBEAN, label: t('caribbean') },
    { value: SectionEnum.EASTERN, label: t('eastern') },
    { value: SectionEnum.FLORIDA, label: t('florida') },
    { value: SectionEnum.HAWAII_PACIFIC, label: t('hawaii pacific') },
    { value: SectionEnum.INTERMOUNTAIN, label: t('intermountain') },
    { value: SectionEnum.INTERNATIONAL, label: t('international') },
    { value: SectionEnum.MID_ATLANTIC, label: t('mid atlantic') },
    { value: SectionEnum.MIDDLE_STATES, label: t('middle states') },
    { value: SectionEnum.MIDWEST, label: t('midwest') },
    { value: SectionEnum.MISSOURI_VALLEY, label: t('missouri valley') },
    { value: SectionEnum.NEW_ENGLAND, label: t('new england') },
    { value: SectionEnum.NORTHERN, label: t('northern') },
    { value: SectionEnum.NORTHERN_CALIFORNIA, label: t('northern california') },
    { value: SectionEnum.PACIFIC_NW, label: t('pacific nw') },
    { value: SectionEnum.SOUTHERN, label: t('sourthern') },
    {
      value: SectionEnum.SOUTHERN_CALIFORNIA,
      label: t('sourthern california'),
    },
    { value: SectionEnum.SOUTHWEST, label: t('southwest') },
    { value: SectionEnum.TEXAS, label: t('texas') },
    { value: SectionEnum.UNASSIGNED, label: t('unassigned') },
  ];
};

export const getDistrictEnumOptions = (
  section: SectionEnum | '',
  t: TFunction,
): { value: DistrictEnum | ''; label: string }[] => {
  if (section === SectionEnum.CARIBBEAN) {
    return [
      { value: DistrictEnum.PUERTO_RICO, label: t('puerto rico') },
      { value: DistrictEnum.VIRGIN_ISLANDS, label: t('virgin islands') },
    ];
  }

  if (section === SectionEnum.EASTERN) {
    return [
      { value: DistrictEnum.WESTERN_REGION, label: t('western region') },
      {
        value: DistrictEnum.METROPOLITAN_REGION,
        label: t('metropolitan region'),
      },
      { value: DistrictEnum.NORTHERN_REGION, label: t('northern region') },
      { value: DistrictEnum.NEW_JERSEY_REGION, label: t('new jersey region') },
      { value: DistrictEnum.LONG_ISLAND_REGION, label: t('long island region') },
      { value: DistrictEnum.SOUTHERN_REGION, label: t('southern region') },
    ];
  }

  if (section === SectionEnum.FLORIDA) {
    return [
      { value: DistrictEnum.REGION_1, label: t('region 1') },
      { value: DistrictEnum.REGION_2, label: t('region 2') },
      { value: DistrictEnum.REGION_3, label: t('region 3') },
      { value: DistrictEnum.REGION_4, label: t('region 4') },
      { value: DistrictEnum.REGION_5, label: t('region 5') },
      { value: DistrictEnum.REGION_6, label: t('region 6') },
      { value: DistrictEnum.REGION_7, label: t('region 7') },
      { value: DistrictEnum.REGION_8, label: t('region 8') },
    ];
  }

  if (section === SectionEnum.HAWAII_PACIFIC) {
    return [
      { value: DistrictEnum.OAHU, label: t('oahu') },
      {
        value: DistrictEnum.AMERICAN_SAMOA_GUAM,
        label: t('american samoa guam'),
      },
      { value: DistrictEnum.EAST_HAWAII, label: t('east hawaii') },
      { value: DistrictEnum.MAUI, label: t('maui') },
      { value: DistrictEnum.WEST_HAWAII, label: t('west hawaii') },
      { value: DistrictEnum.KAUAI, label: t('kauai') },
    ];
  }

  if (section === SectionEnum.INTERMOUNTAIN) {
    return [
      { value: DistrictEnum.IDAHO, label: t('idaho') },
      { value: DistrictEnum.WYOMING, label: t('wyoming') },
      { value: DistrictEnum.COLORADO, label: t('colorado') },
      { value: DistrictEnum.MONTANA, label: t('montana') },
      { value: DistrictEnum.NEVADA, label: t('nevada') },
      { value: DistrictEnum.UTAH, label: t('utah') },
    ];
  }

  if (section === SectionEnum.MID_ATLANTIC) {
    return [
      { value: DistrictEnum.MARYLAND, label: t('maryland') },
      { value: DistrictEnum.VIRGINIA, label: t('virginia') },
      { value: DistrictEnum.WASHINGTON_DC, label: t('washington dc') },
      { value: DistrictEnum.WEST_VIRGINIA, label: t('west virginia') },
    ];
  }

  if (section === SectionEnum.MIDDLE_STATES) {
    return [
      {
        value: DistrictEnum.CENTRAL_PENNSYLVANIA,
        label: t('central pennsylvania'),
      },
      {
        value: DistrictEnum.ALLEGHENY_MOUNTAIN,
        label: t('allegheny mountain'),
      },
      { value: DistrictEnum.PHILADELPHIA, label: t('philadelphia') },
      { value: DistrictEnum.NEW_JERSEY, label: t('new jersey') },
      { value: DistrictEnum.DELAWARE, label: t('delaware') },
      { value: DistrictEnum.EASTERN_PENNSYLVANIA, label: t('eastern pennsylvania') },
    ];
  }

  if (section === SectionEnum.MISSOURI_VALLEY) {
    return [
      { value: DistrictEnum.KANSAS, label: t('kansas') },
      { value: DistrictEnum.OKLAHOMA, label: t('oklahoma') },
      { value: DistrictEnum.MISSOURI, label: t('missouri') },
      { value: DistrictEnum.IOWA, label: t('iowa') },
      { value: DistrictEnum.ST_LOUIS, label: t('st louis') },
      { value: DistrictEnum.HEART_OF_AMERICA, label: t('heart of america') },
      { value: DistrictEnum.NEBRASKA, label: t('nebraska') },
    ];
  }

  if (section === SectionEnum.NEW_ENGLAND) {
    return [
      {
        value: DistrictEnum.WESTERN_MASSACHUSETTS,
        label: t('western massachusetts'),
      },
      { value: DistrictEnum.RHODE_ISLAND, label: t('rhode island') },
      { value: DistrictEnum.CONNECTICUT, label: t('connecticut') },
      { value: DistrictEnum.NEW_HAMPSHIRE, label: t('new hampshire') },
      { value: DistrictEnum.VERMONT, label: t('vermont') },
      { value: DistrictEnum.EASTERN_MASSACHUSETTS, label: t('eastern massachusetts') },
      { value: DistrictEnum.MAINE, label: t('maine') },
    ];
  }

  if (section === SectionEnum.NORTHERN_CALIFORNIA) {
    return [{ value: DistrictEnum.NORTHERN_CALIFORNIA, label: t('northern california') }];
  }

  if (section === SectionEnum.NORTHERN) {
    return [{ value: DistrictEnum.MEMBER, label: t('member') }];
  }

  if (section === SectionEnum.PACIFIC_NW) {
    return [
      {
        value: DistrictEnum.NORTHWEST_WASHINGTON,
        label: t('northwest washington'),
      },
      { value: DistrictEnum.SOUTHERN_OREGON, label: t('southern oregon') },
      { value: DistrictEnum.IDAHO, label: t('idaho') },
      { value: DistrictEnum.NORTHERN_OREGON, label: t('northern oregon') },
      { value: DistrictEnum.BRITISH_COLUMBIA, label: t('british columbia') },
      { value: DistrictEnum.SOUTHWEST_WASHINGTON, label: t('southwest washington') },
      { value: DistrictEnum.EASTERN_WASHINGTON, label: t('eastern washington') },
      { value: DistrictEnum.ALASKA, label: t('alaska') },
    ];
  }

  if (section === SectionEnum.SOUTHERN_CALIFORNIA) {
    return [
      { value: DistrictEnum.SOUTHERN_CALIFORNIA, label: t('southern california') },
      { value: DistrictEnum.SAN_DIEGO, label: t('san diego') },
    ];
  }

  if (section === SectionEnum.SOUTHERN) {
    return [
      { value: DistrictEnum.ALABAMA, label: t('alabama') },
      { value: DistrictEnum.MISSISSIPPI, label: t('mississippi') },
      { value: DistrictEnum.TENNESSEE, label: t('tennessee') },
      { value: DistrictEnum.GEORGIA, label: t('georgia') },
      { value: DistrictEnum.KENTUCKY, label: t('kentucky') },
      { value: DistrictEnum.SOUTH_CAROLINA, label: t('south carolina') },
      { value: DistrictEnum.ARKANSAS, label: t('arkansas') },
      { value: DistrictEnum.LOUISIANA, label: t('louisiana') },
      { value: DistrictEnum.NORTH_CAROLINA, label: t('north carolina') },
    ];
  }

  if (section === SectionEnum.SOUTHWEST) {
    return [
      { value: DistrictEnum.NORTHERN_ARIZONA, label: t('northern arizona') },
      { value: DistrictEnum.GREATER_EL_PASO, label: t('greater el paso') },
      { value: DistrictEnum.CENTRAL_ARIZONA, label: t('central arizona') },
      {
        value: DistrictEnum.NORTHERN_NEW_MEXICO,
        label: t('northern new mexico'),
      },
      {
        value: DistrictEnum.SOUTHERN_NEW_MEXICO,
        label: t('southern new mexico'),
      },
      { value: DistrictEnum.SOUTHERN_ARIZONA, label: t('southern arizona') },
      { value: DistrictEnum.SOUTHEASTERN_NEW_MEXICO, label: t('southeastern new mexico') },
    ];
  }

  if (section === SectionEnum.TEXAS) {
    return [{ value: DistrictEnum.MEMBER, label: t('member') }];
  }

  if (section === SectionEnum.MIDWEST) {
    return [
      { value: DistrictEnum.CHICAGO, label: t('chicago') },
      { value: DistrictEnum.WESTERN_MICHIGAN, label: t('western michigan') },
      { value: DistrictEnum.OHIO_VALLEY, label: t('ohio valley') },
      { value: DistrictEnum.NORTHWESTERN_OHIO, label: t('northwestern ohio') },
      { value: DistrictEnum.NORTHEASTERN_OHIO, label: t('northeastern ohio') },
      { value: DistrictEnum.CENTRAL_INDIANA, label: t('central indiana') },
      {
        value: DistrictEnum.MID_SOUTH_ILLINOIS_TENNIS_ASSOCIATION,
        label: t('mid south illinois tennis association'),
      },
      { value: DistrictEnum.NORTHERN_MICHIGAN, label: t('northern michigan') },
      { value: DistrictEnum.NORTHERN_ILLINOIS, label: t('northern illinois') },
      { value: DistrictEnum.NORTHERN_INDIANA, label: t('northern indiana') },
      { value: DistrictEnum.N_E_MICHIGAN, label: t('ne michigan') },
      { value: DistrictEnum.S_E_MICHIGAN, label: t('se michigan') },
      { value: DistrictEnum.WISCONSIN, label: t('wisconsin') },
    ];
  }

  if (section === SectionEnum.UNASSIGNED) {
    return [{ value: DistrictEnum.UNASSIGNED, label: t('unassigned') }];
  }

  return [];
};

export function getFileName(ranklistName: string) {
  // Replace double spaces with single
  // Replace `&` with 'and'
  return ranklistName?.replaceAll('  ', ' ')?.replaceAll('&', 'and');
}

export function getSearchFilter(searchFilter?: string | null) {
  if (!searchFilter) return {};

  if (Number(searchFilter)) {
    return { participantIdSearch: searchFilter };
  } else {
    return { participantName: searchFilter };
  }
}

export function getLocalityFilters(section?: string | null, district?: string | null) {
  let activeFilters = {};
  if (section) activeFilters = { ...activeFilters, section: { eq: section } };
  if (district) activeFilters = { ...activeFilters, district: { eq: district } };
  return activeFilters;
}

// For multiple participants per ranking item, display fields as "p1Field / p1Field"
export const slashParticipantField = (field: string) => {
  return {
    key: 'participants',
    transforms: [
      {
        operation: CSVValueTransform.ARRAY_JOIN,
        parameters: [
          { key: 'fieldPath', value: field },
          { key: 'delimiter', value: ' / ' },
        ],
      },
    ],
  };
};

export const getDivisionType = (
  playerType: PlayerTypeEnum,
  playerLevel: PlayerLevelEnum | null,
): Record<'divisionType', DivisionTypeEnum> | {} => {
  switch (playerType) {
    case PlayerTypeEnum.ADULT:
      if (playerLevel && playerLevel !== PlayerLevelEnum.Op) {
        return { divisionType: DivisionTypeEnum.NTRP };
      } else {
        return { divisionType: DivisionTypeEnum.AGE };
      }

    case PlayerTypeEnum.JUNIOR:
      return { divisionType: DivisionTypeEnum.AGE };

    case PlayerTypeEnum.WHEELCHAIR:
      if (playerLevel === PlayerLevelEnum.Op) {
        return DivisionTypeEnum.AGE;
      }
      return { divisionType: playerLevel };

    case PlayerTypeEnum.FAMILY:
      return {};

    default:
      return {};
  }
};
