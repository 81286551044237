import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { isDateRangeSameYear } from 'src/components/rankings-run/rankings-run.utils';
import {
  AgeRestrictionEnum,
  ListTypeEnum,
  PlayerTypeEnum,
  RankListSchedulePeriodEnum,
} from 'src/graphql-types/globalRankingTypes';

interface GetFiltersFromQueryParamsProps {
  query: any;
  overrideGenderModifier?: boolean;
}

export const getFiltersFromQueryParams = ({ query, overrideGenderModifier }: GetFiltersFromQueryParamsProps) => {
  const ACCEPTABLE_INPUT_KEYS = [
    'listType',
    'playerType',
    'familyCategory',
    'playerLevel',
    'gender',
    'genderModifier',
    'ageRestriction',
    'matchFormat',
    'matchFormatType',
    'divisionType',
    'region',
  ];

  const validQueryParams = Object.entries(query).filter(([key, value]) => {
    // Always use genderModifier query
    if (key === 'genderModifier' && !overrideGenderModifier) return true;

    return ACCEPTABLE_INPUT_KEYS.includes(key) && value && value !== 'null';
  });
  return Object.fromEntries(validQueryParams);
};

export const getFamilyAgeRestriction = (playerType: any, ageRestriction: any) => {
  if (playerType === PlayerTypeEnum.FAMILY && ageRestriction === 'null')
    return { ageRestriction: AgeRestrictionEnum.NA };
  return {};
};

export const getAgeRestriction = (playerType: any, ageRestriction: any) => {
  if ([PlayerTypeEnum.FAMILY, PlayerTypeEnum.WHEELCHAIR].includes(playerType) && ageRestriction === 'null')
    return { ageRestriction: AgeRestrictionEnum.NA };
  return {};
};

interface GetRankingPeriodProps {
  start: any;
  end: any;
  t: TFunction;
  nextRunAt?: any;
}

export const getRankingPeriod = ({ start, end, t }: GetRankingPeriodProps) => {
  const isSameYear = isDateRangeSameYear(new Date(start), new Date(end));
  const translationString = isSameYear ? 'ranklist date range' : 'ranklist date range diff year';

  return t(translationString, {
    start: dayjs.utc(start?.split('T')[0]),
    end: dayjs.utc(end?.split('T')[0]),
  });
};

export function getNextRankingPeriod({ start, end, nextRunAt, t }: GetRankingPeriodProps) {
  const isSameYear = isDateRangeSameYear(new Date(start), new Date(end));
  const translationString = isSameYear ? 'ranklist date range' : 'ranklist date range diff year';

  if (new Date(nextRunAt).getDate() === new Date(start).getDate()) {
    return t(translationString, {
      start: dayjs.utc(start),
      end: dayjs.utc(end),
    });
  } else {
    return t(translationString, {
      start: dayjs.utc(start),
      end: dayjs.utc(end),
    });
  }
}

export const getSchedulePeriodFromEnum = ({ t, period }: { t: TFunction; period?: RankListSchedulePeriodEnum }) => {
  if (!period) return t('n/a');
  if (period === RankListSchedulePeriodEnum.ONE_MONTH) return t('1 month previous');
  if (period === RankListSchedulePeriodEnum.ONE_YEAR) return t('1 year previous');
};

export function displayScheduledRuns(listType: ListTypeEnum | undefined) {
  const listsWithoutScheduledRuns: ListTypeEnum[] = [ListTypeEnum.L2_QUALIFIER];

  if (listsWithoutScheduledRuns.some((list) => list === listType)) {
    return false;
  }

  return true;
}
