import React from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import { Body } from 'src/components/typography/typography';

import * as styles from './player-move-restrictions-dialog.module.less';

export const PlayerMoveRestrictionsDialog = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <Grid container direction="column" className={styles.container}>
      <Body size="lg">{t('player move restrictions description')}</Body>
      <Body size="lg" bold spacing={{ margins: { md: 'top', xs: 'bottom' } }}>
        {t('player move restrictions question')}
      </Body>
      <li className={styles.listItem}>{t('player move restrictions answer one')}</li>
      <li className={styles.listItem}>{t('player move restrictions answer two')}</li>

      <Body size="lg" bold spacing={{ margins: { md: 'top', xs: 'bottom' } }}>
        {t('when not player move question')}
      </Body>
      <li className={styles.listItem}>{t('when not player move answer one')} </li>
      <li className={styles.listItem}>{t('when not player move answer two')}</li>
      <li className={styles.listItem}>{t('when not player move answer three')}</li>
      <CustomGrid container justifyContent="flex-end" spacing={{ margins: { lg: 'top' } }} onClick={onClose}>
        <Button level="tertiary">{t('close')}</Button>
      </CustomGrid>
    </Grid>
  );
};
