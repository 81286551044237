import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Grid, Popover } from '@mui/material';
import cx from 'classnames';
import { navigate } from 'gatsby';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { meshGatewayClient } from 'src/apollo/client';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import Button from 'src/components/button/button';
import CustomDialog from 'src/components/custom-dialog/custom-dialog';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import DragHandle from 'src/components/drag-handle/drag-handle';
import Dropdown from 'src/components/dropdown/dropdown';
import { RankingDialog } from 'src/components/ranking-dialog/ranking-dialog';
import {
  AddPlayersDialog,
  ChangePositionPopover,
  DeleteDialog,
  InfoLabelItem,
  isPublished,
  onDrop,
  PublishDialog,
  reorderItemsInsideTable,
} from 'src/components/ranking-entry-helper/ranking-entry-helper';
import StatusLabel from 'src/components/status-label/status-label';
import { formatName, getPublishedStatus, ListMethodEnum } from 'src/components/team-rankings/team-rankings';
import {
  GET_PLAYERS,
  GET_RANK_LIST_ITEM,
  PUBLISH_RANK_LIST,
  UNPUBLISH_RANK_LIST,
  UPDATE_RANK_LIST,
} from 'src/components/team-rankings/team-rankings-queries';
import { Body } from 'src/components/typography/typography';
import {
  CellConfigInterface,
  DragObjectItemInterface,
  DropTypeEnum,
  RowConfigInterface,
} from 'src/components/virtualized-table/typedefs';
import VirtualizedTable, { ColumnData } from 'src/components/virtualized-table/virtualized-table';
import { RankListStatusEnum } from 'src/graphql-types/globalITARankingTypes';
import { PersonsByPersonId, PersonsByPersonIdVariables } from 'src/graphql-types/PersonsByPersonId';
import DeleteSVG from 'src/images/icons/16px/delete.svg';
import EditSVG from 'src/images/icons/16px/edit-icon.svg';
import PlusSVG from 'src/images/icons/16px/plus.svg';
import SwapSVG from 'src/images/icons/16px/swap-vertical.svg';
import { userIsGlobalAdmin } from 'src/utils/auth';
import { formatDate } from 'src/utils/helper/membership';
import { formatFullName } from 'src/utils/helper/rankings';
import ROUTES from 'src/utils/routes';

import { BodyLarge, H3, Panel, Spinner, TextInput } from '@clubspark-react/clubspark-react-tools';

import { TD_ARCHIVE_RANK_LISTS } from './ranking-entry-queries';
import * as styles from './ranking-entry.module.less';
import RankingSelectPlayerModal from './ranking-select-player-modal/ranking-select-player-modal';

interface DialogRowData {
  field?: any;
  rowId?: any;
  value?: any;
  label?: any;
  team?: any;
}

const getVisibilityOptions = (t: TFunction) => [
  { value: RankListStatusEnum.Hidden, label: t('hidden') },
  { value: RankListStatusEnum.Published, label: t('published') },
];

enum VisibilityStatusEnum {
  IDLE = 'IDLE',
  HIDDEN = 'HIDDEN',
  PUBLISHED = 'PUBLISHED',
}

const RankingEntry = ({ rankingId }) => {
  const { t } = useTranslation();
  const isGlobalAdmin = userIsGlobalAdmin();
  const [rankingDialog, setRankingDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [publishDialog, setPublishDialog] = useState(false);
  const [playersDialog, setPlayersDialog] = useState(false);
  //  Used to add players to each row
  const [addPlayersDialog, setAddPlayersDialog] = useState(false);
  const [dialogRowData, setDialogRowData] = useState<DialogRowData>({} as DialogRowData);

  const [list, setList] = useState<any>([]);
  const [focusedRow, setFocusedRow] = useState<string>('');
  const [isBeingEdited, setIsBeingEdited] = useState<any>(null);
  const [editedOnce, setEditedOnce] = useState<any>([]);
  const [archiveDialog, setArchiveDialog] = useState(false);

  const {
    data: rankListItemsData,
    loading,
    error,
    refetch,
  } = useQuery(GET_RANK_LIST_ITEM, {
    variables: { id: rankingId },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [getPlayersData, { data: playersData, loading: loadingPlayersData }] = useLazyQuery<
    PersonsByPersonId,
    PersonsByPersonIdVariables
  >(GET_PLAYERS);

  const [publishRankList, { loading: publishingRankList }] = useMutation(PUBLISH_RANK_LIST);
  const [unpublishRankList, { loading: unpublishingRankList }] = useMutation(UNPUBLISH_RANK_LIST);
  const [updateRankingList, { loading: updatingRankingList }] = useMutation(UPDATE_RANK_LIST);
  const [archiveRankLists, { loading: archivingRankLists }] = useMutation(TD_ARCHIVE_RANK_LISTS, {
    client: meshGatewayClient,
  });

  const rankList = useMemo(() => {
    return rankListItemsData?.td_rankList;
  }, [rankListItemsData]);
  const rankListItems = useMemo(() => {
    return rankList?.rankingItems?.items;
  }, [rankListItemsData]);
  const isDouble = useMemo(() => {
    return rankList?.matchFormat === 'DOUBLES';
  }, [rankListItemsData]);
  const isTeam = useMemo(() => {
    return rankList?.matchFormat === 'TEAM';
  }, [rankListItemsData]);
  const listMethod = useMemo(() => {
    return rankList?.listMethod || ListMethodEnum.AUTOMATED;
  }, [rankListItemsData]);

  const rankListName = useMemo(() => {
    return rankList && formatName(rankList, true);
  }, [rankList]);

  function transformData(data) {
    if (!data) {
      return [];
    }

    return data.map((item) => {
      const { players, teams } =
        item.participants?.reduce((map, participant) => {
          if (participant.participantType == 'INDIVIDUAL' || !participant.participantType) {
            map?.players?.length ? map.players.push(participant) : (map.players = [participant]);
          }
          if (participant.participantType == 'TEAM') {
            map?.teams?.length ? map.teams.push(participant) : (map.teams = [participant]);
          }

          return map;
        }, {}) || {};

      const [playerOne, playerTwo = {}] = players?.map(({ itemId }) => getPlayerInfo(itemId)) || [];

      const playerOneName = formatFullName(playerOne?.standardGivenName, playerOne?.standardFamilyName);
      const playerTwoName = formatFullName(playerTwo?.standardGivenName, playerTwo?.standardFamilyName);

      const playerOneTeam = playerOne?.groups?.find((grp) => grp.groupId);
      const playerTwoTeam = playerTwo?.groups?.find((grp) => grp.groupId);

      return {
        id: uuid(),
        playerOneId: playerOne?.id || players?.[0]?.itemId,
        playerTwoId: playerTwo?.id || players?.[1]?.itemId,
        playerOneName: playerOneName || players?.[0]?.name,
        playerTwoName: playerTwoName || players?.[1]?.name,
        playerOneTeamName: playerOneTeam?.groupName || teams?.[0]?.name || '',
        playerTwoTeamName: playerTwoTeam?.groupName || teams?.[0]?.name || '',
        index: item.rank,
        votes: item.votes ? String(item.votes) : '',
        points: item.points?.total ? String(item.points?.total) : '',
      };
    });
  }
  useEffect(() => {
    if (rankListItemsData) {
      const personIds = rankListItems?.flatMap((x) => x.participants?.map((p) => p.itemId));
      const uniquePersonIds = [...new Set(personIds)];

      getPlayersData({
        variables: {
          personIds: uniquePersonIds.filter(Boolean) as string[],
        },
      });
    }
  }, [rankListItemsData]);

  useEffect(() => {
    if (rankListItemsData) {
      //  If `maxPlayers` === 0 remap value to ''
      //  Used in Edit List modal to show empty string and not '0' value
      if (rankListItemsData?.rankList?.publishCount === 0) {
        rankListItemsData.rankList.publishCount = '';
      }

      const transformedData = transformData(rankListItems);

      if (rankListItems && list.length == 0) {
        const ids = transformedData.map((item) => item.id);
        setEditedOnce(ids);
        setList(transformedData);
      }
    }
  }, [rankListItemsData]);

  useEffect(() => {
    // Ranklist not published
    if (!rankList?.visible) setVisibilityStatus(VisibilityStatusEnum.HIDDEN);
    // Ranklist published
    if (rankList?.visible) setVisibilityStatus(VisibilityStatusEnum.PUBLISHED);
  }, [rankList?.visible]);

  // Adds index to all list items
  const handleTableData = useCallback(() => list.map((item, index) => ({ ...item })), [list]);
  const tableData = handleTableData();

  const isRowFocused = (rowId: string): boolean => focusedRow === rowId;
  const isRowBeingEdited = (rowId: string): boolean => {
    if (!rowId) {
      return false;
    }

    return isBeingEdited?.id === rowId;
  };

  const manyPersons = playersData?.personsByPersonId || [];
  const handlePlayersOptions = useCallback(() => {
    return manyPersons.map((player) => ({
      label: formatFullName(player.standardGivenName, player.standardFamilyName),
      value: player.personId,
    }));
  }, [playersData]);

  const cellConfig: CellConfigInterface = {
    className: styles.cellConfig,
  };

  const rowConfig: RowConfigInterface = {
    draggableRow: () => true,
    rowSize: () => 55,
    className: styles.rowConfig,
  };

  const onDropTable = (
    dragObject: DragObjectItemInterface<any>, // UMTableDataType | UMScheduleTableDataType
    endIndex: number,
    dropType: DropTypeEnum,
    dropRowId?: string,
  ) => {
    onDrop(dragObject, dropType, dropRowId, endIndex, list, setList);
  };

  interface SetDataProps {
    field: string;
    value: string;
    label?: Record<string, unknown>;
    rowId: string;
    team?: {};
  }

  function setData({ field, value, label = {}, rowId, team = {} }: SetDataProps): void {
    const updatedList = list.map((item) => {
      if (item.id === rowId) {
        return {
          ...item,
          [field]: value,
          ...label,
          ...team,
        };
      }

      return item;
    });

    setList(updatedList);

    getPlayersData({
      variables: {
        personIds: updatedList.map((x) => x.playerOneId).filter(Boolean),
      },
    });
  }

  function getPlayerInfo(playerId: string) {
    return manyPersons.find((p) => p.personId === playerId);
  }

  const deleteRow = (row) => {
    const updatedList = tableData.filter((item) => item.id !== row.id);
    const filteredList = removeTies(row, updatedList);
    const updatedEditedOnce = editedOnce.filter((item) => item !== row.id);

    setEditedOnce(updatedEditedOnce);
    setList(filteredList);
  };

  const removeTies = (row, list) => {
    return list.map((item) => {
      if (item?.tie === row?.tie) {
        return {
          ...item,
          tie: '',
        };
      }

      return item;
    });
  };

  const handleEditRow = (action) => {
    if (action === 'cancel') {
      const updatedList = list.map((item) => {
        if (item.id === isBeingEdited.id) {
          return isBeingEdited;
        }

        return item;
      });

      setList(updatedList);
    }

    setIsBeingEdited(null);
  };
  const [visibilityStatus, setVisibilityStatus] = useState<VisibilityStatusEnum>(VisibilityStatusEnum.IDLE);
  const isVisibilityStatusPublished = visibilityStatus === VisibilityStatusEnum.PUBLISHED;

  const movePositionNumber = (list, row, newPosition) => {
    const startIndex = row.index - 1;
    const endIndex = newPosition - 1;
    const updatedList = reorderItemsInsideTable(list, startIndex, endIndex);
    setList(updatedList);
  };

  const addPlayerRow = (location: string, row) => {
    const currentRowIndex = location === 'above' ? row.index - 1 : row.index;
    const [lastRow] = tableData.slice(-1);

    const nextIndex = lastRow.index;

    const updatedList = [...tableData, { id: uuid(), getTitle: () => 'title' }];

    const sortedList = reorderItemsInsideTable(updatedList, nextIndex, currentRowIndex);
    setList(sortedList);
  };

  enum AddPlayerRowsEnum {
    BELOW = 'Below position',
    ABOVE = 'Above position',
  }

  const addPlayerRows = ({ where, numOfPlayers, posNumber }) => {
    const newRows = Array.from(Array(numOfPlayers).keys()).map((item) => ({
      id: uuid(),
      getTitle: () => 'title',
    }));

    const index = where === AddPlayerRowsEnum.BELOW ? posNumber : posNumber - 1;

    const before = tableData.slice(0, index);
    const after = tableData.slice(index);

    const updatedList = [...before, ...newRows, ...after];
    setList(updatedList);
    setPlayersDialog(false);
  };

  async function handlePublish(status: RankListStatusEnum) {
    if (status === RankListStatusEnum.Published) {
      await publishRankList({
        variables: {
          ids: [rankingId],
        },
      });
    }
    if (status === RankListStatusEnum.Hidden) {
      await unpublishRankList({
        variables: {
          ids: [rankingId],
        },
      });
    }
    await refetch();
    setPublishDialog(false);
  }
  async function handleArchiveRanklist() {
    await archiveRankLists({ variables: { ids: [rankingId] } });
    navigate(ROUTES.RANKINGS);
  }

  const tieRow = (row, direction) => {
    if (direction === 'above') {
      const aboveRow = tableData.find((item) => item.index === row.index - 1);
      const tie = `T-${aboveRow?.index}`;

      const updatedList = tableData.map((item: any) => {
        if (aboveRow?.tie && row?.tie) {
          return item;
        }

        if (item.id === aboveRow?.id) {
          return { ...item, tie: aboveRow?.tie || tie };
        }

        if (item?.tie) {
          return item;
        }

        if (item.id === row.id) {
          if (aboveRow?.tie) {
            return { ...item, tie: aboveRow.tie };
          } else {
            return {
              ...item,
              tie,
            };
          }
        }

        return item;
      });

      setList(updatedList);
    } else {
      const belowRow = tableData.find((item) => item.index === row.index + 1);
      const tie = `T-${row.index}`;

      const updatedList = tableData.map((item: any) => {
        if (belowRow?.tie && row?.tie) {
          return item;
        }

        // Add tie index to row below
        if (item.id === belowRow?.id) {
          return { ...item, tie: row?.tie || tie };
        }

        // If row below is tied, change its tie index and all other linked rows to current row index
        if (belowRow?.tie && item?.tie === belowRow?.tie) {
          return { ...item, tie };
        }

        // if row has a tie index, leave it the same
        if (item?.tie) {
          return item;
        }

        if (item.id === row.id) {
          return { ...item, tie };
        }

        return item;
      });

      setList(updatedList);
    }
  };

  const removeTieLink = (row) => {
    const numberOfTies = tableData.filter((item) => item?.tie === row?.tie);
    const itemsBelowRow = tableData.filter((item) => item.index < row.index);
    const first = numberOfTies[0];
    const last = numberOfTies[numberOfTies.length - 1];

    const updatedList = tableData.map((item) => {
      if (
        (numberOfTies.length < 3 && item?.tie === row?.tie) ||
        (itemsBelowRow.length > 1 && item.index >= row.index) ||
        (itemsBelowRow.length < 2 && first !== row && last !== row) ||
        item.id === row.id
      ) {
        return {
          ...item,
          tie: '',
        };
      } else {
        return item;
      }
    });

    setList(updatedList);
  };

  async function handleSaveRankings() {
    setFocusedRow('');
    // Remove items without player field
    const filteredData = tableData.filter((item) => Boolean(item.playerOneId) || Boolean(item.playerTwoId));

    const rankItems = filteredData.map((item) => {
      return {
        personIds: !item.playerTwoId ? [item.playerOneId] : [item.playerOneId || '', item.playerTwoId],
        rank: item.index,
        votes: Number(item.votes),
        team: item.playerOneTeamId || item.playerTwoTeamId,
        // type: item.player,
        points: Number(item.points),
      };
    });

    try {
      await updateRankingList({
        variables: {
          id: rankingId,
          input: {
            rankingItems: rankItems,
            totalEntries: rankItems.length,
          },
        },
      });
    } catch (error) {}
  }

  const columns: ColumnData<any>[] = useMemo(
    () => [
      {
        key: 'index',
        getTitle: () => ({ node: t('rank'), className: styles.rankHeadCell }),
        getValue: (row) => {
          const [anchorEl, setAnchorEl] = React.useState<any>(null);
          const popoverOpen = Boolean(anchorEl);
          const popoverId = popoverOpen ? 'index-popover' : undefined;

          return {
            node: (
              <div onClick={(event) => setAnchorEl(event?.currentTarget)} className={styles.indexContainer}>
                {row.tie && <span className={styles.tiedColumn}>{row.tie}</span>}
                {!row.tie && row.index}
              </div>
            ),
            className: styles.rankCell,
          };
        },
      },
      {
        key: 'drag',
        getTitle: () => ({ node: '', className: styles.dragHeadCell }),
        getValue: (row) => ({
          node: getPlayerInfo(row?.playerOneId) ? <DragHandle /> : <></>,
          className: styles.dragHandleCell,
          isDragHandle: true,
        }),
        hidden: () => listMethod == ListMethodEnum.AUTOMATED,
      },
      {
        key: 'player',
        getTitle: () => ({
          node: isTeam ? 'Team' : isDouble ? t('player one') : t('player'),
          className: isTeam ? styles.teamHeadCell : styles.playerHeadCell,
        }),
        getValue: (row) => {
          useEffect(() => {
            if (row.playerOneId && !isRowFocused(row.id) && !editedOnce.includes(row.id)) {
              setEditedOnce([...editedOnce, row.id]);
            }
          }, [row]);

          if (isTeam) {
            return {
              node: row.playerOneTeamName,
              className: styles.teamCell,
            };
          }

          if (isRowBeingEdited(row.id)) {
            return {
              node: (
                <>
                  <p style={{ margin: 0 }}>{row.playerOneName}</p>
                  {row.playerOneId && (
                    <div>
                      <a href="#" onClick={() => openPlayerSelectModal(row, 'playerOneId')}>
                        {t('change')}
                      </a>
                    </div>
                  )}
                </>
              ),
              className: isTeam ? styles.teamCell : styles.playerCell,
            };
          } else if (editedOnce.includes(row.id) && row.playerOneId) {
            return {
              node: row.playerOneName,
              className: isTeam ? styles.teamCell : styles.playerCell,
            };
          } else if (row.playerOneId) {
            return {
              node: row.playerOneName,
              className: isTeam ? styles.teamCell : styles.playerCell,
            };
          } else {
            return {
              node: (
                <Button style={{ height: 32 }} onClick={() => openPlayerSelectModal(row, 'playerOneId')} type="button">
                  {isTeam ? t('add team') : t('add player')}
                </Button>
              ),
              className: isTeam ? styles.teamCell : styles.playerCell,
            };
          }
        },
      },
      {
        key: 'playerOneTeam',
        getTitle: () => ({
          node: !isDouble ? t('team') : t('player one team'),
          className: styles.playerCell,
        }),
        getValue: (row) => {
          if (!row.playerOneId) {
            return {
              node: '',
              className: styles.playerCell,
            };
          } else {
            return {
              node: row.playerOneTeamName || row.playerOneTeam,
              className: styles.playerCell,
            };
          }
        },
        hidden: () => isTeam,
      },
      {
        key: 'playerTwo',
        getTitle: () => ({ node: t('player two'), className: styles.playerHeadCell }),
        getValue: (row) => {
          useEffect(() => {
            if (row.playerTwoId && !isRowFocused(row.id) && !editedOnce.includes(row.id)) {
              setEditedOnce([...editedOnce, row.id]);
            }
          }, [row]);

          if (isRowBeingEdited(row.id)) {
            return {
              node: (
                <>
                  <p style={{ margin: 0 }}>{row.playerTwoName}</p>
                  {row.playerTwoId && (
                    <div>
                      <a href="#" onClick={() => openPlayerSelectModal(row, 'playerTwoId')}>
                        {t('change')}
                      </a>
                    </div>
                  )}
                </>
              ),
              className: styles.playerCell,
            };
          } else if (editedOnce.includes(row.id) && row.playerTwoId) {
            return {
              node: row.playerTwoName,
              className: styles.playerCell,
            };
          } else if (row.playerTwoId) {
            return {
              node: row.playerTwoName,
              className: styles.playerCell,
            };
          } else {
            return {
              node: (
                <Button style={{ height: 32 }} onClick={() => openPlayerSelectModal(row, 'playerTwoId')} type="button">
                  Add player
                </Button>
              ),
              className: styles.playerCell,
            };
          }
        },
        hidden: () => !isDouble,
      },
      {
        key: 'playerTwoTeam',
        getTitle: () => ({ node: t('player two team'), className: styles.playerCell }),
        getValue: (row) => {
          if (!row.playerTwoId) {
            return {
              node: '',
              className: styles.playerCell,
            };
          } else {
            return {
              node: row.playerTwoTeamName || row.playerTwoTeam,
              className: styles.playerCell,
            };
          }
        },
        hidden: () => !isDouble,
      },
      {
        key: 'schoolYear',
        getTitle: () => ({ node: t('school year'), className: styles.schoolYearHeadCell }),
        getValue: (row) => ({
          node: getPlayerInfo(row.playerOneId)?.schoolYear,
          className: styles.schoolYearCell,
        }),
        hidden: () => true,
      },
      {
        key: 'votes',
        getTitle: () => ({ node: `#1 ${t('votes')}`, className: styles.votesHeadCell }),
        getValue: (row) => {
          if (!row.playerOneId) {
            return {
              node: '',
              className: styles.votesCell,
            };
          } else if (isRowBeingEdited(row.id)) {
            return {
              node: (
                <TextInput
                  outlined
                  disableUnderline
                  defaultValue={row?.votes}
                  onBlur={(e) =>
                    setData({
                      field: 'votes',
                      value: e.target.value,
                      rowId: row.id,
                    })
                  }
                />
              ),
              className: styles.votesCell,
            };
          } else if (row.playerOneId && (!isRowFocused(row.id) || editedOnce.includes(row.id))) {
            return {
              node: row.votes,
              className: styles.votesCell,
            };
          } else {
            return {
              node: (
                <TextInput
                  outlined
                  disableUnderline
                  defaultValue={row?.votes}
                  onBlur={(e) =>
                    setData({
                      field: 'votes',
                      value: e.target.value,
                      rowId: row.id,
                    })
                  }
                />
              ),
              className: styles.votesCell,
            };
          }
        },
        hidden: () => listMethod == ListMethodEnum.AUTOMATED,
      },
      {
        key: 'points',
        getTitle: () => ({ node: t('points'), className: styles.pointsHeadCell }),
        getValue: (row) => {
          if (!row.playerOneId) {
            return {
              node: row.points,
              className: styles.pointsCell,
            };
          } else if (isRowBeingEdited(row.id)) {
            return {
              node: (
                <TextInput
                  outlined
                  disableUnderline
                  defaultValue={row?.points}
                  onBlur={(e) =>
                    setData({
                      field: 'points',
                      value: e.target.value,
                      rowId: row.id,
                    })
                  }
                />
              ),
              className: styles.pointsCell,
            };
          } else if (row.playerOneId && (!isRowFocused(row.id) || editedOnce.includes(row.id))) {
            const sorted = [...tableData].sort((a, z) => z.points - a.points);
            const sortedIndex = sorted.findIndex((item) => item.id === row.id);

            const shouldBeLower = sortedIndex > row.index;

            const pointsMatchIndex = sorted[row.index - 1]?.id === row.id;

            return {
              node: row.points,
              className: styles.pointsCell,
            };
          } else {
            return {
              node: (
                <TextInput
                  outlined
                  disableUnderline
                  defaultValue={row?.points}
                  onBlur={(e) =>
                    setData({
                      field: 'points',
                      value: e.target.value,
                      rowId: row.id,
                    })
                  }
                />
              ),
              className: styles.pointsCell,
            };
          }
        },
      },
    ],
    [list, setList, focusedRow, isBeingEdited, editedOnce, isTeam, isDouble, playersData],
  );

  const statusLabel = () => {
    const status = getPublishedStatus(rankList, t);
    return <StatusLabel variety={status.color}>{status.text}</StatusLabel>;
  };

  const openPlayerSelectModal = (row, field) => {
    //  Prepare first first data values since `fieldId` and `rowId`  values are in this component
    const rowData = {
      field: field,
      rowId: row.id,
    };
    setDialogRowData(rowData);
    setAddPlayersDialog(true);
  };

  const closePlayerSelectModal = () => {
    setDialogRowData({} as DialogRowData);
    setAddPlayersDialog(false);
  };

  const preparePlayerData = (label: string, value: string, teamName: string, teamId: string) => {
    //  Prepare second data values `label` and `value`
    //  They are selected from child component
    const dataObj: DialogRowData = {
      ...dialogRowData,
      value: value,
    };

    if (dialogRowData.field === 'playerOneId') {
      dataObj.label = { playerOneName: label };
      dataObj.team = { playerOneTeam: teamName, playerOneTeamId: teamId };
    }
    if (dialogRowData.field === 'playerTwoId') {
      dataObj.label = { playerTwoName: label };
      dataObj.team = { playerTwoTeam: teamName };
    }

    setDialogRowData(dataObj);
  };

  const onSave = () => {
    // @ts-ignore
    setData(dialogRowData);
    closePlayerSelectModal(); //  Close modal
  };

  return (
    <>
      <Breadcrumbs
        paths={[
          { name: t('rankings'), to: ROUTES.RANKINGS },
          {
            name: rankListName || rankList?.id,
          },
        ]}
      />
      <div>
        <Panel>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {rankListItems?.length > 0 && (
                <Grid container>
                  <Grid container item xs>
                    <div className={styles.breakWordContainer}>
                      <H3>{rankListName || rankList?.id}</H3>
                    </div>
                  </Grid>
                  <CustomGrid container item xs justifyContent="flex-end">
                    <Body size="lg" light spacing={{ margins: { sm: 'right', xs: 'top' } }}>
                      {t('publish status')}:
                    </Body>
                    <div className={styles.dropdownWrapper}>
                      <div
                        className={cx(styles.dropdownDecoration, {
                          [styles.dropdownDecorationHidden]: rankList?.visible === false,
                          [styles.dropdownDecorationPublished]: rankList?.visible === true,
                        })}
                      />
                      <Dropdown
                        options={getVisibilityOptions(t)}
                        selected={rankListItemsData?.td_rankList?.visible ? t('published') : t('hidden')}
                        onSelect={(option) => handlePublish(option.value)}
                        spacing={{ margins: { xs: 'horizontal' } }}
                        disabled={!rankList || updatingRankingList || loading}
                        darkBorder
                      />
                    </div>
                    {listMethod == ListMethodEnum.MANUAL && (
                      <>
                        <Button onClick={() => setRankingDialog(true)} level="tertiary">
                          {t('edit')}
                        </Button>
                        <Button spacing={{ margins: { xs: 'left' } }} level="tertiary">
                          {t('view online')}
                        </Button>
                        <Button
                          spacing={{ margins: { xs: 'left' } }}
                          level="warningOutlined"
                          onClick={() => setDeleteDialog(true)}
                        >
                          {t('delete')}
                        </Button>
                      </>
                    )}
                  </CustomGrid>
                  <CustomGrid
                    spacing={{ margins: { sm: 'left' } }}
                    hide={rankListItems?.length > 0 && (!isGlobalAdmin || isVisibilityStatusPublished)}
                  >
                    <Button level="secondary" onClick={() => setArchiveDialog(true)}>
                      {t('archive')}
                    </Button>
                  </CustomGrid>
                </Grid>
              )}
              <div className={styles.divider} />
              <Grid container>
                <InfoLabelItem
                  label="Run Date"
                  value={formatDate(rankList?.calculatedDate, 'MM/DD/YYYY')}
                  styleOverride={styles.infoLabelOverride}
                />
                <InfoLabelItem label="Status" value={statusLabel()} />
                <InfoLabelItem label="List method" value={listMethod || ListMethodEnum.AUTOMATED} />
                <InfoLabelItem
                  label={isTeam ? t('max teams listing') : t('max players listing')}
                  value={rankList?.publishCount || (isTeam ? t('all teams') : t('all players'))}
                />
              </Grid>
            </>
          )}
        </Panel>
        <CustomDialog
          title={t('archive list')}
          open={archiveDialog}
          hideX
          overridePosition={{ width: '40%', margin: '0 auto' }}
          content={<Body size="md">{t('archive list question')}</Body>}
          onClose={() => setArchiveDialog(false)}
          actions={
            <CustomGrid container justifyContent="flex-end" className={styles.archiveActionContainer}>
              <Button
                level="tertiary"
                spacing={{ margins: { sm: 'right' } }}
                onClick={() => setArchiveDialog(false)}
                disabled={archivingRankLists}
              >
                {t('cancel')}
              </Button>
              <Button onClick={handleArchiveRanklist} loading={archivingRankLists}>
                {t('confirm')}
              </Button>
            </CustomGrid>
          }
        />
        <div className={styles.tablePanel}>
          <div className={styles.topPanel}>
            {listMethod == ListMethodEnum.MANUAL && (
              <Grid container>
                <Grid container item className={styles.buttonContainer} justifyContent="flex-end" alignItems="flex-end">
                  <Button type="button" onClick={() => setPlayersDialog(true)} level="tertiary">
                    {isTeam ? t('add teams') : t('add players')}
                  </Button>
                  <Button
                    type="submit"
                    onClick={handleSaveRankings}
                    spacing={{ margins: { sm: 'left' } }}
                    loading={updatingRankingList}
                  >
                    {t('save rankings')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
          <VirtualizedTable
            id="rankingsTable"
            loading={loading || loadingPlayersData}
            columns={columns}
            data={tableData}
            cellConfig={cellConfig}
            rowConfig={rowConfig}
            onDrop={onDropTable}
            onRowClick={(event, rowItem, rowIndex) => {
              setFocusedRow(rowItem?.id);
            }}
          />
        </div>
        <CustomDialog
          title={t('edit ranking list')}
          open={rankingDialog}
          hideX
          content={
            <RankingDialog
              setRankingDialog={setRankingDialog}
              initialValues={{ ...rankList, id: rankingId }}
              refetch={refetch}
              isTeam={isTeam}
            />
          }
          onClose={() => setRankingDialog(false)}
        />
        <CustomDialog
          title={t('delete ranking')}
          open={deleteDialog}
          hideX
          content={<DeleteDialog name={rankList?.id} id={rankingId} setDeleteDialog={setDeleteDialog} />}
          onClose={() => setDeleteDialog(false)}
        />
        <CustomDialog
          title={isTeam ? t('add team rows') : t('add player rows')}
          open={playersDialog}
          hideX
          content={
            <AddPlayersDialog
              setPlayersDialog={setPlayersDialog}
              isTeam={isTeam}
              addPlayerRows={addPlayerRows}
              tableData={tableData}
            />
          }
          onClose={() => setPlayersDialog(false)}
        />
        <CustomDialog
          title={t('publish ranking list')}
          open={publishDialog}
          hideX
          content={<PublishDialog name={rankList?.id || ''} isPublished={rankList?.visible} />}
          actions={
            <CustomGrid container justifyContent="flex-end" spacing={{ margins: { xs: ['right', 'bottom'] } }}>
              <Button
                level="tertiary"
                spacing={{ margins: { sm: 'right' } }}
                onClick={() => setPublishDialog(false)}
                disabled={updatingRankingList}
              >
                {t('no, cancel')}
              </Button>
              {!isPublished(rankList?.visible) && (
                <Button onClick={() => handlePublish(rankList?.visible)} loading={updatingRankingList}>
                  {t('yes, publish')}
                </Button>
              )}
              {isPublished(rankList?.visible) && (
                <Button onClick={() => handlePublish(rankList?.visible)} loading={updatingRankingList}>
                  {t('yes, hide')}
                </Button>
              )}
            </CustomGrid>
          }
          onClose={() => setPublishDialog(false)}
        />

        <RankingSelectPlayerModal
          openModal={addPlayersDialog}
          isTeam={isTeam}
          onClose={() => closePlayerSelectModal()}
          preparePlayerData={preparePlayerData}
          onSave={onSave}
        />
      </div>
    </>
  );
};

export default RankingEntry;
